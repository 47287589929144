import { Pie } from "@ant-design/plots"
import React from "react"

export type PieChartData = {
  type: string
  value: number
}

export default function PieChart(props: { data: PieChartData[] }) {
  const config = {
    data: props.data,
    angleField: "value",
    colorField: "type",
    color: ["#6F1D3C", "#7A6855", "#D3B37D", "#1b436a", "#235F4C"],
    radius: 0.7,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 36,
      content: `{name}\n{percentage}`,
    },
    height: 200,
  }
  // @ts-ignore
  return <Pie {...config} />
}
