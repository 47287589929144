import axios from "axios"

export type Data = {
  id: string
  email: string
  firstName: string
  lastName: string
  gender: string
  bots: Bot[]
}

export type ErrorData = {
  id?: string
  text: string
  timestamp: string
  category: string
}

export type BotData = {
  id?: string
  month: string
  year: string
  scheduled: number
  executed: number
  succeeded: number
  failed: number
  stopped: number
  suspended: number
  resumed: number
  averageExecutionTime: number
  quartileExecutionTimes: {
    id?: string
    quartile25: number | null
    quartile50: number | null
    quartile75: number | null
    quartile100: number | null
  }
  errors: ErrorData[]
}

export type Bot = {
  id?: string
  entity: string
  botName: string
  data: BotData[]
}

export type BotMetaData = {
  name: string
  UserBotOwn: {
    botId: string
    userId: string
  }
}

function computeAggregation(bots: Bot[]) {
  const dataByMonths: BotData[] = []

  for (const bot of bots) {
    for (const data1 of bot.data) {
      const dataByMonthIndex = dataByMonths.findIndex((d) => d.month === data1.month)

      if (dataByMonthIndex > -1) {
        dataByMonths[dataByMonthIndex] = {
          month: dataByMonths[dataByMonthIndex].month,
          year: dataByMonths[dataByMonthIndex].year,
          scheduled: dataByMonths[dataByMonthIndex].scheduled + data1.scheduled,
          executed: dataByMonths[dataByMonthIndex].executed + data1.executed,
          succeeded: dataByMonths[dataByMonthIndex].succeeded + data1.succeeded,
          failed: dataByMonths[dataByMonthIndex].failed + data1.failed,
          stopped: dataByMonths[dataByMonthIndex].stopped + data1.stopped,
          suspended: dataByMonths[dataByMonthIndex].suspended + data1.suspended,
          resumed: dataByMonths[dataByMonthIndex].resumed + data1.resumed,
          averageExecutionTime: dataByMonths[dataByMonthIndex].averageExecutionTime,
          quartileExecutionTimes: {
            quartile25: dataByMonths[dataByMonthIndex].quartileExecutionTimes.quartile25 || 0,
            quartile50: dataByMonths[dataByMonthIndex].quartileExecutionTimes.quartile50 || 0,
            quartile75: dataByMonths[dataByMonthIndex].quartileExecutionTimes.quartile75 || 0,
            quartile100: dataByMonths[dataByMonthIndex].quartileExecutionTimes.quartile100 || 0,
          },
          errors: [...dataByMonths[dataByMonthIndex].errors, ...data1.errors],
        }
      } else {
        dataByMonths.push({
          month: data1.month,
          year: data1.year,
          scheduled: data1.scheduled,
          executed: data1.executed,
          succeeded: data1.succeeded,
          failed: data1.failed,
          stopped: data1.stopped,
          suspended: data1.suspended,
          resumed: data1.resumed,
          averageExecutionTime: data1.averageExecutionTime,
          quartileExecutionTimes: {
            quartile25: data1.quartileExecutionTimes.quartile25 || 0,
            quartile50: data1.quartileExecutionTimes.quartile50 || 0,
            quartile75: data1.quartileExecutionTimes.quartile75 || 0,
            quartile100: data1.quartileExecutionTimes.quartile100 || 0,
          },
          errors: data1.errors,
        })
      }
    }
  }

  return {
    entity: "aggregation",
    botName: "Tous les robots",
    data: dataByMonths,
  }
}

function computeErrorsAggregation(bots: Bot[]) {
  const errors = []

  for (const bot of bots) {
    for (const data of bot.data) {
      errors.push({
        name: bot.botName,
        month: data.month,
        errorNumber: data.errors.length,
        errorPercentage: Math.trunc((data.errors.length * 100) / data.executed),
      })
    }
  }

  return errors
}

type DisplayedErrors = {
  name: string
  month: string
  errorNumber: number
  errorPercentage: number
}

let errorsAggregation: DisplayedErrors[]

function AddAggregationToData(data: Bot[]) {
  errorsAggregation = computeErrorsAggregation(data)

  const aggregation = computeAggregation(data)

  data.unshift(aggregation)
}

const backendUrl = process.env.REACT_APP_BACKEND_URL

const httpInstance = axios.create({
  baseURL: backendUrl,
  timeout: 3000,
})

export { errorsAggregation, httpInstance, AddAggregationToData }
