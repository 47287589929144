import "./IterationStatus.css"
import PieChart from "./PieChart"
import IterationTable from "./IterationTable"
import Content from "./Content"
import { BotData } from "../Logic/AppState"
import dayjs from "dayjs"
import { QuartileTable } from "./QuartileTable"

type QuartileTableRow = {
  header: string
  values: number[]
}

function fillMissingMonth(monthHeaders: number[], quartileRows: QuartileTableRow[]) {
  monthHeaders.forEach((month, index) => {
    if (!quartileRows.find((row) => row.header === dayjs().month(month).format("MMM"))) {
      quartileRows.splice(index, 0, {
        header: dayjs().month(month).format("MMM"),
        values: [0, 0, 0, 0],
      })
    }
  })
}

export default function IterationStatus(props: { botData: BotData[]; month: string; aggregation: boolean }) {
  const monthValue = parseInt(props.month)
  dayjs.locale("en")
  const month = dayjs().month(monthValue).format("MMMM")
  dayjs.locale("fr")

  const monthIndex = props.botData.findIndex((data) => data.month === month)

  let pieChartData

  if (monthIndex > -1) {
    pieChartData = [
      { type: "Succès", value: props.botData[monthIndex]?.succeeded },
      { type: "Arrêté", value: props.botData[monthIndex]?.stopped },
      { type: "Erreur(s)", value: props.botData[monthIndex]?.failed },
    ]
  } else {
    pieChartData = [
      { type: "Succès", value: 0 },
      { type: "Arrêté", value: 0 },
      { type: "Erreur(s)", value: 0 },
    ]
  }

  const tableData = [
    {
      rowHeader: "Itérations planifiées",
      data: props.botData.map((data) => {
        dayjs.locale("en")
        const monthIndex = dayjs.months().findIndex((month) => data.month === month)
        dayjs.locale("fr")

        return {
          value: data.scheduled,
          month: dayjs().month(monthIndex).format("MMM"),
        }
      }),
    },
    {
      rowHeader: "Itérations effectuées",
      data: props.botData.map((data) => {
        dayjs.locale("en")
        const monthIndex = dayjs.months().findIndex((month) => data.month === month)
        dayjs.locale("fr")

        return {
          value: data.executed,
          month: dayjs().month(monthIndex).format("MMM"),
        }
      }),
    },
    {
      rowHeader: "Temps moyen de traitement (min)",
      data: props.botData.map((data) => {
        dayjs.locale("en")
        const monthIndex = dayjs.months().findIndex((month) => data.month === month)
        dayjs.locale("fr")

        return {
          value: data.averageExecutionTime,
          month: dayjs().month(monthIndex).format("MMM"),
        }
      }),
    },
  ]

  const currentMonth = dayjs().month(monthValue).month()
  const monthLess1 = dayjs().month(monthValue).subtract(1, "month").month()
  const monthLess2 = dayjs().month(monthValue).subtract(2, "month").month()
  const monthHeaders = [currentMonth, monthLess1, monthLess2]

  const quartileRows: QuartileTableRow[] = []

  props.botData.forEach((data) => {
    dayjs.locale("en")
    const monthNumber = dayjs()
      .month(dayjs.months().findIndex((m) => m === data.month))
      .month()
    dayjs.locale("fr")

    if (monthHeaders.includes(monthNumber)) {
      quartileRows.push({
        header: dayjs().month(monthNumber).format("MMM"),
        values: [
          data.quartileExecutionTimes.quartile25 || 0,
          data.quartileExecutionTimes.quartile50 || 0,
          data.quartileExecutionTimes.quartile75 || 0,
          data.quartileExecutionTimes.quartile100 || 0,
        ],
      })
    }
  })

  if (quartileRows.length < 3) {
    fillMissingMonth(monthHeaders, quartileRows)
  }

  return (
    <div className={"section"}>
      <div className={"section-title"}>Statuts des itérations</div>
      <div className={"section-content"}>
        <Content title={"Statuts des itérations planifiées"} id="planned-iteration">
          <IterationTable month={monthValue} rows={tableData}></IterationTable>
        </Content>
        <Content title={"Statuts des itérations réalisées"} id={"executed-iteration"}>
          <PieChart data={pieChartData}></PieChart>
        </Content>
        {props.aggregation ? (
          ""
        ) : (
          <Content title={"Temps moyen par quartile (en minutes)"} id={"quartile-time"}>
            <QuartileTable rows={quartileRows}></QuartileTable>
          </Content>
        )}
      </div>
    </div>
  )
}
