import "./QuartileTable.css"

type QuartileTableRow = {
  header: string
  values: number[]
}

export function QuartileTable(props: { rows: QuartileTableRow[] }) {
  const columnHeader = ["Q1", "Q2", "Q3", "Q4"]
  const { rows } = props

  return (
    <div className={"quartile-table"}>
      <div className={"group-quartile-table-col-header"}>
        {columnHeader.map((header) => (
          <div className={"quartile-table-col-header"} key={header}>
            {header}
          </div>
        ))}
      </div>
      {rows.map((row, rowIndex) => {
        return (
          <div className={"quartile-table-row"} key={rowIndex}>
            <div className={"quartile-table-row-header"}>{row.header}</div>
            {row.values.map((value, index) => (
              <div className={"quartile-value"} key={row.header + index}>
                {Math.round(value / 60).toLocaleString("fr")}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
