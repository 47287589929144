import "./help-menu.css"
import React, { useState } from "react"

export default function HelpMenu(props: {}) {
  const [isDisplayedHelpMenu, setDisplayedHelpMenu] = useState(false)

  function handleDisplayMenu(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()

    if (isDisplayedHelpMenu) {
      setDisplayedHelpMenu(false)
    } else {
      setDisplayedHelpMenu(true)
    }
  }

  return (
    <div className={"help-menu-container"}>
      <div className={"issue-icon"} onClick={handleDisplayMenu}>
        <div className={"issue-icon-style"}>{isDisplayedHelpMenu ? "X" : "?"}</div>
      </div>
      {isDisplayedHelpMenu ? (
        <div className={"help-menu"}>
          <a
            className={"help-menu-item"}
            target="_blank"
            href="mailto:can.atac@ca-indosuez.com?subject=Problème reporting RPA&cc=lydie.percier@ca-indosuez.com"
            rel="noopener noreferrer"
          >
            <div className={"help-menu-item-text"}>Autre</div>
            <div className={"circle"}></div>
          </a>
          <a
            className={"help-menu-item"}
            target="_blank"
            href="mailto:can.atac@ca-indosuez.com?subject=Reporting RPA : Erreur d'owner&body=Bonjour,%0D%0A%0D%0ALe robot <indiquez le nom> est présent dans mon reporting mais il ne me concerne pas, merci de l’enlever.%0D%0A%0D%0ABien cordialement,%0D%0A&cc=lydie.percier@ca-indosuez.com"
            rel="noopener noreferrer"
          >
            <div className={"help-menu-item-text"}>Erreur d'owner</div>
            <div className={"circle"}></div>
          </a>
          <a
            className={"help-menu-item"}
            target="_blank"
            href="mailto:can.atac@ca-indosuez.com?subject=Reporting RPA : Robot manquant&body=Bonjour,%0D%0A%0D%0AJ’ai remarqué que le robot <indiquez le nom> est manquant dans mon reporting, pourriez-vous le rajouter s’il vous plait %3F%0D%0A%0D%0ABien cordialement,%0D%0A&cc=lydie.percier@ca-indosuez.com"
            rel="noopener noreferrer"
          >
            <div className={"help-menu-item-text"}>Robot manquant</div>
            <div className={"circle"}></div>
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

// (indiquez le nom) est manquant dans mon reporting, pourriez-vous le rajouter s’il vous plait ? <br><br>Bien cordialement,<br>
