import dayjs from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"
import utc from "dayjs/plugin/utc"
import "dayjs/locale/fr"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthsShort: ["Janv.", "Fév.", "Mars", "Avril", "Mai", "Juin", "Juill.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
})
dayjs.locale("fr")

type Option = {
  value: string
  label: string
}

type MonthNames = {
  value: string
  label: string
  abbreviation: string
}

const months: MonthNames[] = []
const years: Option[] = []

let startYear = 2023
while (startYear <= dayjs().year()) {
  years.push({
    value: startYear.toString(),
    label: startYear.toString(),
  })
  startYear++
}

let startMonth = 0
while (startMonth < 12) {
  months.push({
    value: startMonth.toString(),
    label: dayjs().month(startMonth).format("MMMM"),
    abbreviation: dayjs().month(startMonth).format("MMM"),
  })
  startMonth++
}

export { years, months }
