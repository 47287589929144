import { ReactNode } from "react"

export enum Display {
  row,
  column,
}

export default function Content(props: { title: string; children: ReactNode; display?: Display; id: string }) {
  return (
    <div className={"main-content shadow"} id={props.id}>
      <h2 className={"title-content"}>{props.title}</h2>
      <div className={`content ${props.display === Display.row ? "content-row" : ""}`}>{props.children}</div>
    </div>
  )
}
