import "./IterationTable.css"
import dayjs from "dayjs"

type TableRowData = {
  value: number
  month: string
}

type TableRows = {
  data: TableRowData[]
  rowHeader: string
}

function getHeaderIndex(data: TableRowData[], header: string, subtract: number): number {
  const month = dayjs.monthsShort().findIndex((month) => month === header)

  if (month > -1) {
    return data.findIndex(
      (data: { month: string }) => data.month === dayjs().month(month).subtract(subtract, "month").format("MMM"),
    )
  }
  return -1
}

export default function IterationTable(props: { month: number; rows: TableRows[] }) {
  const currentMonth = dayjs().month(props.month).format("MMM")

  const headers = []
  headers.push(dayjs().month(props.month).subtract(2, "month").format("MMM"))
  headers.push(dayjs().month(props.month).subtract(1, "month").format("MMM"))
  headers.push(currentMonth)

  return (
    <>
      <div className={"iteration-table"}>
        <div className={"iteration-table-headers"}>
          {headers.map((header) => {
            return (
              <div className={"iteration-table-header"} key={header}>
                {header}
              </div>
            )
          })}
        </div>
        <div className={"iteration-table-rows"}>
          <div>
            {props.rows.map((row) => {
              return (
                <div className={"iteration-row-header"} key={row.rowHeader}>
                  {row.rowHeader}
                </div>
              )
            })}
          </div>
          {headers.map((header, index) => {
            return (
              <div className={"group-iteration-table-row"} key={index}>
                <div className={"iteration-table-row"} key={props.rows[0].rowHeader + header + 0}>
                  {props.rows[0].data[getHeaderIndex(props.rows[0].data, header, 0)]?.value.toLocaleString("fr") || 0}
                </div>
                <div className={"iteration-table-row"} key={props.rows[0].rowHeader + header + 1}>
                  {props.rows[0].data[getHeaderIndex(props.rows[0].data, header, 0)]?.value.toLocaleString("fr") || 0}
                </div>
                <div className={"iteration-table-row"} key={props.rows[0].rowHeader + header + 2}>
                  {(
                    Math.ceil(props.rows[2].data[getHeaderIndex(props.rows[2].data, header, 0)]?.value / 60) || 0
                  ).toLocaleString("fr")}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
