import "./ErrorByRobotTable.css"
import dayjs from "dayjs"
import { ErrorAggregation } from "./ErrorsFocus"

export default function ErrorByRobotTable(props: { data: ErrorAggregation[]; month: string }) {
  const errors = props.data.filter((d) => {
    dayjs.locale("en")
    const month = dayjs().month(parseInt(props.month)).format("MMMM")
    dayjs.locale("fr")

    return d.month === month
  })

  return (
    <div className={"error-table"}>
      <div className="error-table-headers">
        <div className="error-table-header">Nom du robot</div>
        <div className="error-table-header">Nombre</div>
        <div className="error-table-header">%</div>
      </div>
      {errors.map((err, index) => {
        return (
          <div className={"error-table-items"} key={err.name + index}>
            <div
              className={err.errorPercentage >= 5 ? "error-table-item error-table-item-bg-error" : "error-table-item"}
            >
              {err.name}
            </div>
            <div
              className={err.errorPercentage >= 5 ? "error-table-item error-table-item-bg-error" : "error-table-item"}
            >
              {err.errorNumber}
            </div>
            <div
              className={err.errorPercentage >= 5 ? "error-table-item error-table-item-bg-error" : "error-table-item"}
            >
              {err.errorPercentage}
            </div>
          </div>
        )
      })}
    </div>
  )
}
