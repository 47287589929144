import { Bar } from "@ant-design/plots"
import { ErrorData } from "../Logic/AppState"
import "./BarChart.css"

export default function BarChart(props: { data: ErrorData[] | undefined; month: string }) {
  const { data } = props

  const getData = () => {
    const template: any = []

    if (!data) {
      return template;
    }

    const categories: any = {}

    data.forEach((error) => {
      if (!categories[error.category]) {
        categories[error.category] = 1
      } else {
        categories[error.category]++
      }
    })

    for (const [key, value] of Object.entries(categories)) {
      template.push({category: key, value})
    }

    return template.sort((a: any, b: any) => {
       return a.value - b.value
    })
  }

  const config = {
    // data: template.filter((elem) => elem.value > 0), //todo idee de filtrage pour plus tard
    data: getData(),
    forceFit: true,
    yField: "category",
    xField: "value",
    seriesField: "category",
    color: ["#6F1D3C", "#D3B37D", "#7A6855", "#1b436a", "#235F4C"],
    maxBarWidth: 20,
    height: 200,
    // autoAdjust: true,
  }

  return <Bar {...config} legend={false} className={"bar-chart"}></Bar>
}
