import "./Loader.css"

export function Loader() {
  return (
    <div className="loader">
      <span>Récupération des données</span>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
