import React, { useEffect, useState } from "react"
import "antd/dist/reset.css"
import "./App.css"
import { Home } from "./components/Home"
import { AddAggregationToData, Bot, httpInstance } from "./Logic/AppState"
import { Loader } from "./components/Loader"

export default function App() {
  const [appUserId, setAppUserId] = useState<String>("")
  const [userData, setUserData] = useState<Bot[]>()
  const [isLoading, setLoadingStatus] = useState(true)

  async function getUserData(userId: string) {
    try {
      const res = await httpInstance.get(`/user/${userId}/bot/data`)

      AddAggregationToData(res.data.data)

      setUserData(res.data.data)
    } catch (e) {
      console.error("user not found")
      setLoadingStatus(false)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get("id")

    if (userId) {
      const fetchData = async () => {
        await getUserData(userId)
        setAppUserId(userId)
        setLoadingStatus(false)
      }

      fetchData().catch(console.error)
    } else {
      setLoadingStatus(false)
    }
  }, [])

  return (
    <>
      {isLoading ? <Loader></Loader> : <Home userId={appUserId} userData={userData} setUserData={setUserData}></Home>}
    </>
  )
}
