import "./LogTable.css"
import dayjs from "dayjs"
import { BotData, ErrorData } from "../Logic/AppState"

type Column = {
  id: string
  date: string
  text: string
  category: string
}

export default function LogTable(props: { data: ErrorData[] | undefined; headers: string[]; month: string }) {
  const { data, headers } = props

  let columns: Column[]

  if (data) {
    data.sort(function (a, b) {
      // @ts-ignore
      return new Date(a.timestamp) - new Date(b.timestamp)
    })
    columns = data.map((error) => {
      return {
        id: error.id!.toString(),
        date: dayjs(error.timestamp).toString(),
        text: error.text,
        category: error.category,
      }
    })
  } else {
    columns = []
  }

  return (
    <>
      {headers.map((header, index) => {
        return (
          <div className={"log-table-column"} key={index}>
            <div className={"log-table-column-header"} key={header}>
              {header}
            </div>
            {columns.length > 0
              ? columns.map((col) => {
                  if (index === 0) {
                    return (
                      <div className={"log-table-column-item log-table-column-date"} key={col.date + col.id}>
                        {dayjs(col.date).format("DD/MM")}
                      </div>
                    )
                  } else if (index === 1) {
                    return (
                      <div className={"log-table-column-item log-table-column-hour"} key={col.date + col.id}>
                        {dayjs(col.date).format("hh:mm")}
                      </div>
                    )
                  } else if (index === 2) {
                    return (
                      <span className={"log-table-column-item log-table-column-type"} key={col.category + col.id}>
                        {col.category}
                      </span>
                    )
                  } else {
                    return (
                      <div className={"log-table-column-item log-table-column-text"} key={col.text + col.id}>
                        {col.text}
                      </div>
                    )
                  }
                })
              : ""}
          </div>
        )
      })}
    </>
  )
}
