import React from "react"
import Content, { Display } from "./Content"
import BarChart from "./BarChart"
import LogTable from "./LogTable"
import { BotData, ErrorData } from "../Logic/AppState"
import ErrorByRobotTable from "./ErrorByRobotTable"
import "./ErrorFocus.css"
import dayjs from "dayjs"

export type Column = {
  date: string
  text: string
}

export type ErrorAggregation = {
  name: string
  month: string
  errorPercentage: number
  errorNumber: number
}

function getErrors(botData: BotData[], month: string): ErrorData[] | undefined {
  return botData.find((d) => {
    dayjs.locale("en")
    const monthToFind = dayjs().month(parseInt(month)).format("MMMM")
    dayjs.locale("fr")

    return d.month === monthToFind
  })?.errors
}

function displayAggregation(errorsAggregation: ErrorAggregation[], month: string, botData: BotData[]) {
  return (
    <>
      <Content title={"Nombre d'erreurs par robot"} id={"errors-number"}>
        <ErrorByRobotTable data={errorsAggregation} month={month}></ErrorByRobotTable>
      </Content>
      <Content title={"Motif des erreurs observées sur le mois"} id={"errors-types"}>
        <BarChart data={getErrors(botData, month)} month={month}></BarChart>
      </Content>
    </>
  )
}

function displayError(month: string, botData: BotData[]) {
  const errors = getErrors(botData, month)

  if (errors && errors.length > 0) {
    return (
      <>
        <Content title={"Journal des erreurs"} display={Display.row} id={"errors-log"}>
          <LogTable data={errors} headers={["Date", "Heure", "Type", "Erreurs"]} month={month}></LogTable>
        </Content>
        <Content title={"Motif des erreurs observées sur le mois"} id={"errors-types"}>
          <BarChart data={errors} month={month}></BarChart>
        </Content>
      </>
    )
  } else {
    return <>Pas d'erreur à afficher</>
  }
}

export default function ErrorsFocus(props: {
  botData: BotData[]
  month: string
  aggregation: boolean
  errorsAggregation: ErrorAggregation[]
}) {
  return (
    <div className={"section"}>
      <div className={"section-title"}>Focus erreurs</div>
      <div className={"section-content"}>
        {props.aggregation
          ? displayAggregation(props.errorsAggregation, props.month, props.botData)
          : displayError(props.month, props.botData)}
      </div>
    </div>
  )
}
