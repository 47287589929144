import React from "react"
import { Select, ConfigProvider } from "antd"

export type Option = {
  value: string
  label: string
}

const tokenProperties = {
  borderRadius: 8,
  controlOutlineWidth: 0,
  fontSize: 19,
}

interface SelectProps {
  options: Option[]
  defaultValue?: Option
  width: number
  handler: (value: string) => void
  secondary?: boolean
  arrow?: boolean
}

class SelectComponent extends React.Component<SelectProps> {
  secondary: boolean
  width: number
  handler: (value: string) => void
  options: Option[]
  defaultValue?: Option
  open: boolean = false

  constructor(props: SelectProps) {
    super(props)
    this.secondary = props.secondary || false
    this.width = props.width
    this.handler = props.handler
    this.options = props.options
    this.defaultValue = props.defaultValue
  }

  render() {
    return (
      <ConfigProvider
        theme={{
          token: this.secondary
            ? {
                ...tokenProperties,
                colorBgContainer: "#D3B37D",
                colorBorder: "#D3B37D",
                colorBgBase: "#D3B37D",
                colorText: "#fff",
                controlItemBgActive: "#ab9a7f",
                colorIcon: "#fff",
              }
            : {
                ...tokenProperties,
                colorBgContainer: "#FFFBFE",
                colorBorder: "#FFFBFE",
                colorBgBase: "#FFFBFE",
                colorText: "#757575",
                controlItemBgActive: "#D6D1D8",
                colorIcon: "#fff",
              },
        }}
      >
        <Select
          className="shadow"
          defaultValue={this.defaultValue ? this.defaultValue.value : this.options[0].value}
          style={{ width: this.width }}
          options={this.options}
          listHeight={330}
          dropdownMatchSelectWidth={true}
          onChange={this.handler}
        />
      </ConfigProvider>
    )
  }
}

export default SelectComponent
