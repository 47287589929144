import "./unknown.css"
import logo from "../logo_indosuez.svg"
import warning from "../warning.png"
import React, { useEffect, useState } from "react";
import HelpMenu from "./HelpMenu";
import { AddAggregationToData, httpInstance } from "../Logic/AppState";

export function Unknown() {
  const [backVersion, setBackVersion] = useState<string>("")
  const frontVersion = process.env.REACT_APP_HASH

  useEffect(() => {
    const getData = async () => {
      const back = await httpInstance.get("/")

      if (back?.data?.version) {
        setBackVersion(back.data.version)
      }
    }

    getData().catch(console.error)
  }, [])

  return (
    <div className={"app"}>
      <img src={logo} className="app-logo" alt="logo" />
      <div className="page-bg">
        <h1 className="app-title">Reporting mensuel d'activité RPA</h1>
        <section className={"main-home"}>
          <div className={"home-container shadow"}>
            <img src={warning} alt="" />
            <div className="home-container-text">
              Pour accéder au suivi de vos robots, connectez-vous via le lien présent dans le mail reçu chaque début de
              mois
            </div>
          </div>
        </section>
        <div className={"home-footer"}>
          <div className={"home-footer-version-container"}>
            {frontVersion ? <span className={"home-footer-version"}>F : {frontVersion}</span> : " "}
            {backVersion ? <span className={"home-footer-version"}>B : {backVersion}</span> : " "}
          </div>
          <HelpMenu></HelpMenu>
        </div>
      </div>
    </div>
  )
}
